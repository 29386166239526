a {
  color: blueviolet;
}

a:hover {
  cursor: pointer;
}

a:visited {
  color: hotpink
}

html, body {
  width: 100%;
  margin: 0; 
  padding: 0; 
  overflow-x: hidden;
}

h1 {
  font-size: 4em;
  margin-top: .5%;
}

.AppContainer {
  opacity:0.8;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  z-index:1000;
  background: repeating-linear-gradient(
  45deg,
  #FFFF,
  #FFFF 20px,
  #66CED6 20px,
  #66CED6 30px
);
}


.ContentContainer {
  background-color: rgba(255,255,255,0.65);
  display: flex;
  flex-direction: column;
  font-family: Roboto, "Helvetica Neue";
  height: 100%;
  justify-content: space-between;
  width: -webkit-fill-available;
}



.AboutMeContainer {
  align-content: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 2rem 5rem;
  text-align: left;
  overflow: scroll;
  height: 100%;
  border-left: 40px white solid;
  border-right: 40px white solid;
  border-top: 40px white solid;}

.AboutMeText {
  font-size:  1.5em;
  margin-top: 20px;
}

.BLMLink {
 font-size: 1.5em;
 font-weight: 900;
 text-align: right;
 margin-bottom: 5px;
 position: absolute;
 top: 4rem;
 right: 7rem;
}

.FooterContainer {
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 0;
  color: white;
  border-top: black .30rem solid;
  border-bottom: black .30rem solid;
}

.LinksContainer {
  align-items: center;
  display: flex;
  padding: 1em 2rem;
  justify-content: space-between;
}

.LinksLeft {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.PhotoLink {
  font-size: 1.2rem;
  font-weight: 600;
 }
 

@media only screen and (max-width: 600px) {

  h1 {
    font-size: 3em;
  }

  .AboutMeText {
    font-size: 1rem;
    line-height: 1rem;
    margin-top: 20px;
  }
  
  .AboutMeContainer {
    padding: 2rem 2rem;
  }

  .BLMLink {
    text-align: left;
    margin-bottom: 5px;
    position: relative;
    top: unset;
    right: unset;
   }

  .LinksContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
} 